import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/layout'
import SEO from "../components/seo"
import { rhythm } from '../utils/typography'
import "bootstrap/dist/css/bootstrap.min.css"

class BlogIndex extends React.Component {
  render() {
    const mainContent = get(
      this,
      'props.data.cosmicjsSettings.metadata.main_content'
    )
    const posts = get(this, 'props.data.allCosmicjsPosts.edges')
    const author = get(this, 'props.data.cosmicjsSettings.metadata')
    const location = get(this, 'props.location')

    return (
      <Layout location={location}>
        <SEO title="Lean Software Delivery Blog" />
        <Helmet title="Lean Software Delivery Blog" />
        <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: mainContent}} />
        </div>
        <div class="col-md-3">
        {posts.map(({ node }) => {
          const image = node.metadata.hero.imgix_url
          const title = get(node, 'title') || node.slug
          return (
            <div class="container">
              <div class="row">
                <div class="col">
                  <img
                    src={image}
                    alt={title}
                    style={{
                      height: rhythm(6),
                    }}
                  />
                </div>
              </div>
              <div class="row">
                <div class="col" key={node.slug}>                  
                  <div
                    dangerouslySetInnerHTML={{ __html: node.metadata.description }}
                  />
                  <Link style={{ boxShadow: 'none' }} to={`posts/${node.slug}`}>
                    {title}
                  </Link>
                  <div><small>{node.created}</small></div>
                </div>
              </div>
            </div>
          )
        })}
        </div>
        </div>
        <Bio settings={author} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    allCosmicjsPosts(sort: { fields: [created], order: DESC }, limit: 1000) {
      edges {
        node {
          metadata {
            description            
            hero {
              gatsbyImageData(
                formats: JPG,
                placeholder: DOMINANT_COLOR
              )
              imgix_url
            }
          }
          slug
          title
          created(formatString: "DD MMMM, YYYY")
        }
      }
    }
    cosmicjsSettings(slug: { eq: "general" }) {
      metadata {
        site_title
        main_content
        author_name
        author_bio
        author_avatar {
          imgix_url
        }
      }
    }
  }
`
